import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import leaguesReducer from '../features/leagues/leaguesSlice';
import teamsReducer from '../features/teams/teamsSlice';
import gwReducer from '../features/gw/gwSlice';
import staffReducer from '../features/staff/staffSlice';
import settingsReducer from '../features/settings/settingsSlice';
import logger from 'redux-logger'
import authMiddleware from '../features/auth/authMiddleware';
import config from '../config/config';

const store = configureStore({
  reducer: {
    auth: authReducer,
    leagues: leaguesReducer,
    teams: teamsReducer,
    gw: gwReducer,
    staff: staffReducer,
    settings: settingsReducer
  },
  middleware: (getDefaultMiddleware) => {
    const middlewares = [authMiddleware];

    if (config.debug) {
      middlewares.push(logger);
    }

    return getDefaultMiddleware().concat(middlewares);
  },
});

export default store;