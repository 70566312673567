import React, { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { fetchStaffGwData, saveStaffGwData } from '../features/staff/staffActions';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../components/Loading';
import ErrorPage from './ErrorPage';
import GwsMatchManagement from './GwsMatchManagement';
import ToastMessage from '../components/ToastMessage';
import MatchModal from './MatchModal';

function GwsManagement () {
  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { gwDataLoaded, gwDataError, gwData, gwDataSaved, gwDataSavedSuccess, gwDataSavedError } = useSelector((state) => state.staff);
  const [gw, setGw] = useState(null);
  const [insertMatchModal, setInsertMatchModal] = useState(false);
  const [matches, setMatches] = useState([]);
  const gwOptions = [{value: null, label: ''}];
  for (let i = 1; i <= 38; i++) {
    gwOptions.push({value: i, label: `${t('general.gw')} ${i}`});
  }
  const prevData = usePrevious({ gwData, gwDataSavedSuccess, gwDataSavedError });

  useEffect(() => {
    if (gwData && prevData && !prevData.gwData) {
      setMatches([...gwData.matches]);
    }
  }, [gwData])

  const changeGw = (value) => {
    setGw(value);
    if (value.value) {
      dispatch(fetchStaffGwData(value.value));
    }
  }

  const insertMatch = () => {
    setInsertMatchModal(true);
  }

  const saveGw = (isReady) => {
    dispatch(saveStaffGwData({number: gw.value, data: {isReady, matches}}));
  }

  const resetEdit = () => {
    setMatches([...gwData.matches]);
  }

  const removeMatch = (index) => {
    const newMatches = [...matches];
    newMatches.splice(index, 1);
    setMatches(newMatches);
  }

  const editMatch = (data, index) => {
    const newMatches = [...matches];
    newMatches[index] = data;
    setMatches(newMatches);
  }

  return (
    <>
      <div>
        <div style={{ width: '300px' }}>
          <Select
            options={gwOptions}
            value = {gw}
            onChange={(value) => {changeGw(value)}}
          />
        </div>
        {!gwDataLoaded ? <Loading /> : (
          <>
            {gwDataError ? <ErrorPage /> : (
              <>
                {gwData && gwData.data && gwData.data.status === 'FINISHED' && <h3>{t('staff.finished')}</h3>}
                {gwData && gwData.data && gwData.data.status === 'PENDING' && <h3>{t('staff.pending')}</h3>}
                {gwData && gwData.data && gwData.data.status === 'OPEN' && <h3>{t('staff.open')}</h3>}
                {gwDataSaved ? (
                  <>
                    {gw && (
                      <>
                        {gwDataSavedError && prevData && !prevData.gwDataSavedError && <ToastMessage type="danger" text={t('general.errorSave')} />}
                        {gwDataSavedSuccess && prevData && !prevData.gwDataSavedSuccess && <ToastMessage type="success" text={t('general.saved')} />}
                        {!matches || matches.length === 0 ? <h3>{t('staff.noMatches')}</h3> : matches.map((match, index) => (
                          <GwsMatchManagement key={index} index={index} match={match} onRemove={removeMatch}  onEdit={(index, data) => editMatch(data, index)} edit={gwData && gwData.data && gwData.data.status === 'SCHEDULED'} />
                        ))}
                        {gwData && gwData.data && gwData.data.status === 'SCHEDULED' && <button className='edit' onClick={() => insertMatch()}>{t('staff.insertMatch')}</button>}
                        {matches && matches.length>0 && gwData && gwData.data && gwData.data.status === 'SCHEDULED' && (
                          <div style={{textAlign: 'center'}}>
                            <button onClick={() => saveGw()}>{t('general.save')}</button>
                            {gwData.data.openable && <button style={{marginLeft: '10px'}} onClick={() => saveGw(true)}>{t('staff.saveOpen')}</button>}
                            <button className='back' style={{marginLeft: '10px'}} onClick={() => resetEdit()}>{t('general.cancel')}</button>
                          </div>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <Loading />
                )}
              </>
            )}
          </>
        )}
      </div>
      {insertMatchModal && <MatchModal toggle={() => setInsertMatchModal(false)} onSubmit={(data) => setMatches([...matches, data])} />}
    </>
  )
}

export default GwsManagement;
