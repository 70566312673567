import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ToastMessage from '../components/ToastMessage';
import PredictionsMatch from './PredictionsMatch';
import { useDispatch, useSelector } from 'react-redux';
import { insertGwPredictions } from '../features/gw/gwActions';
import Loading from '../components/Loading';

function PredictionsEdit ({ gwDetails}) {
  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { myId } = useSelector((state) => state.auth);
  const { data } = useSelector((state) => state.settings);
  const { insertPredictionsLoaded, insertPredictionsError, insertPredictionsSuccess } = useSelector((state) => state.gw);
  const [errorForm, setErrorForm] = useState("");
  const [predictions, setPredictions] = useState([]);
  const [captain, setCaptain] = useState(gwDetails.matches.findIndex(match => match.captain));
  const prevPrediction = usePrevious({insertPredictionsSuccess, insertPredictionsError});

  const savePredictions = () => {
    const sendingData = [];
    for (let i=0; i<gwDetails.matches.length; i++) {
      if (predictions[i] === null || (!predictions[i] && (!gwDetails.matches || !gwDetails.matches[i] || !gwDetails.matches[i].prediction))) {
        setErrorForm(t('predictions.missingFields'));
        return;
      }
      if (data.captainEnabled && !captain && captain !== 0) {
        setErrorForm(t('predictions.missingCaptain'));
        return;
      }

      sendingData.push({
        match: gwDetails.matches[i].id,
        choice: predictions[i] ? predictions[i].value : gwDetails.matches[i].prediction,
        captain: data.captainEnabled && captain === i
      })
    }
    dispatch(insertGwPredictions({id: myId, data: sendingData}));
  }

  const changeValue = (value, index) => {
    predictions[index] = value;
    setPredictions([...predictions]);
  }

  return (
    <React.Fragment>
      {prevPrediction && !prevPrediction.insertPredictionsError && insertPredictionsError ? <ToastMessage type="danger" text={t("predictions.errorSaving")} />: errorForm && <ToastMessage type="danger" text={errorForm} />}
      {!insertPredictionsLoaded ? <Loading /> : (
        <>
          {gwDetails.prediction ? <ToastMessage type="success" text={t('predictions.sent')} /> : <ToastMessage type="warning" text={t('predictions.notSent')} />}
          {(prevPrediction && !prevPrediction.insertPredictionsSuccess && insertPredictionsSuccess) ? <ToastMessage type="success" text={t('predictions.saved')} /> : <></>}
          {gwDetails.matches.map((match, index) => (
            <PredictionsMatch key={match.id} match={match} onChangePrediction={(value) => changeValue(value, index)} onChangeCaptain={(value) => setCaptain(value ? index : null)} isCaptain={captain === index}></PredictionsMatch>
          ))}
          <div class="text-center items-center text-normal">
            <button class="text-bold" onClick={savePredictions}
            >{t('predictions.save')}</button>
          </div>
        </>
      )}
    </React.Fragment>
  )
}

export default PredictionsEdit
