import React from 'react';
import { useTranslation } from 'react-i18next';
import ToastMessage from '../components/ToastMessage';

function PredictionsResult ({ gwDetails}) {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <h2>{gwDetails.status === 'PENDING'  && t('predictions.pending')}
      {gwDetails.status === 'FINISHED'  && t('predictions.finished')}</h2>
      {!gwDetails.prediction && (
        <ToastMessage type="warning" text={t('predictions.notSent')} />
      )}
      <h3>{t('predictions.points')}: {gwDetails.prediction ? gwDetails.prediction.points || 0 : 0}</h3>
      {gwDetails.prediction && gwDetails.matches.map((match, index) => (
        <div key={match.id}>
          {match.home} - {match.away} : {match.prediction ? `${match.differential ? '⭐️ ' : ''}${match.captain ? '©️ ': ''}${match.prediction.substring(0, match.prediction.lastIndexOf(' -'))} (${match.prediction.substring(match.prediction.lastIndexOf(' -')+3)}) ${match.score || match.score === 0 ? `- ${match.score}` : ''}` : 'N/A'}
        </div>
      ))}
    </React.Fragment>
  )
}

export default PredictionsResult
