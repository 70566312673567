import React, { useEffect, useRef, useState } from 'react';
import { Label, FormGroup } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { passwordRecovery } from '../features/auth/authActions';
import { useTranslation } from 'react-i18next';
import Loading from '../components/Loading';
import { useNavigate } from 'react-router-dom';
import ToastMessage from '../components/ToastMessage';
import i18next from 'i18next';

const PasswordRecoveryPage = () => {
  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const { t } = useTranslation();
  const [errorForm, setErrorForm] = useState("");
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { passwordRecoveryLoading, passwordRecoveryOk, passwordRecoveryError } = useSelector((state) => state.auth);
  const prevPasswordRecovery = usePrevious({passwordRecoveryError, passwordRecoveryOk});

  const onSubmit = (event) => {
    event.preventDefault();
    if (!email) {
      setErrorForm(t('auth.missingFieldsRegister'));
      return;
    }
    if (!email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )) {
      setErrorForm(t('auth.wrongEmailRegister'));    
      return;  
    }
    dispatch(passwordRecovery({ email, lang: i18next.language }));
  }

  const onChangeEmail = (event) => {
    setEmail(event.target.value);
  }
  return (
    <>
      {passwordRecoveryLoading ? <Loading /> : (
        <>
          {errorForm && <ToastMessage type="danger" text={errorForm} />}
          {passwordRecoveryError && prevPasswordRecovery && !prevPasswordRecovery.passwordRecoveryError && <ToastMessage type="danger" text={t("auth.errorPasswordRecovery")} />}
          {passwordRecoveryOk && prevPasswordRecovery && !prevPasswordRecovery.passwordRecoveryOk && <ToastMessage type="success" text={t("auth.passwordRecovered")} />}
          
          <Label for="email">{t('auth.recoveryHeader')}</Label>
          <FormGroup>
            <Label for="email">{t('auth.email')}</Label>
            <input
              type="text"
              name="email"
              id="email"
              value={email}
              onChange={onChangeEmail}
            />
          </FormGroup>
          <div style={{textAlign: 'center'}}>
            <button onClick={onSubmit}>{t('auth.recovery')}</button>
            <button className="back" style={{marginLeft: '10px'}} onClick={() => navigate('/')}>{t('general.back')}</button>
          </div>
        </>
      )}
    </>
  );
};

export default PasswordRecoveryPage;