import { createSlice } from "@reduxjs/toolkit"
import { fetchGwDetails, fetchGws, insertGwPredictions } from "./gwActions";
import { logoutUser } from "../auth/authActions";

const initialState = {
  gwsLoaded: false,
  gwsError: false,
  gws: [],
  gwDetailsLoaded: false,
  gwDetailsError: false,
  gwDetails: null,
  insertPredictionsLoaded: true,
  insertPredictionsError: false,
  insertPredictionsSuccess: false,
}

const gwSlice = createSlice({
  name: 'gw',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchGws.pending, (state) => {
        state.gwsLoaded = false
        state.gwsError = false
        state.gws = []
      })
      .addCase(fetchGws.fulfilled, (state, action) => {
        state.gwsLoaded = true
        state.gws = action.payload
      })
      .addCase(fetchGws.rejected, (state) => {
        state.gwsError = true
      })

      .addCase(fetchGwDetails.pending, (state) => {
        state.gwDetailsLoaded = false
        state.gwDetailsError = false
        state.gwDetails = null
      })
      .addCase(fetchGwDetails.fulfilled, (state, action) => {
        state.gwDetailsLoaded = true
        state.gwDetails = action.payload
      })
      .addCase(fetchGwDetails.rejected, (state) => {
        state.gwDetailsLoaded = true
        state.gwDetailsError = true
      })
      
      .addCase(insertGwPredictions.pending, (state) => {
        state.insertPredictionsLoaded = false
        state.insertPredictionsError = false
        state.insertPredictionsSuccess = false
      })
      .addCase(insertGwPredictions.fulfilled, (state, action) => {
        const matches = state.gwDetails.matches;
        for (let i=0; i<matches.length; i++) {
          matches[i].prediction = action.payload.find(item => item.match === matches[i].id).choice;
        }
        state.insertPredictionsLoaded = true
        state.insertPredictionsSuccess = true
        state.gwDetails = {
          ...state.gwDetails,
          prediction: {
            time: new Date().getTime(),
          },
          matches,
        }
      })
      .addCase(insertGwPredictions.rejected, (state) => {
        state.insertPredictionsLoaded = true
        state.insertPredictionsError = true
      })
      .addCase(logoutUser.fulfilled, (state) => {        
        state.gws = []
        state.gwsLoaded = false
        state.gwDetails = null
      });
  },
})

export default gwSlice.reducer;