import React, { Component } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Transition } from 'react-transition-group';

const duration = 100;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
};

const transitionStyles = {
  entering: {
    opacity: 1,
  },
  entered: {
    opacity: 1,
  },
  exiting: {
    opacity: 0,
  },
  exited: {
    opacity: 0,
  },
};

class ToastMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  componentDidMount() {
    const { disableAutoClose, onClose, closeTimeout } = this.props;
    this.show = setTimeout(() => {
      this.setState({ visible: true });
    }, 100);
    if (!disableAutoClose) {
      this.close = setTimeout(() => {
        this.setState({ visible: false }, () => onClose && onClose());
      }, closeTimeout);
    }
  }

  componentWillUnmount() {
    const { disableAutoClose } = this.props;
    clearTimeout(this.show);
    if (!disableAutoClose) {
      clearTimeout(this.close);
    }
  }

  handleClose() {
    const { onClose } = this.props;
    this.setState({ visible: false }, () => onClose && onClose());
  }

  render() {
    const {
      text,
      type,
      dismissable,
      ref,
    } = this.props;

    const { visible } = this.state;

    return (
      <Transition in={visible} timeout={duration} unmountOnExit>
        {(state) => (
          <div
            className={`alert alert-${type}`}
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
            ref={ref}
          >
            <div>{text}</div>
            {dismissable && (
              <button
                onClick={() => this.handleClose()}
                type="button"
                className="close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            )}
          </div>
        )}
      </Transition>
    );
  }
}

export default ToastMessage;
