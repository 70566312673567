import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { fetchLeagueDetails } from '../features/leagues/leaguesActions';
import { useTranslation } from 'react-i18next';
import Loading from '../components/Loading';
import ErrorPage from './ErrorPage';
import { fetchGws } from '../features/gw/gwActions';

function LeagueResult () {
  const { t } = useTranslation();
  let { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { teamDetails } = useSelector((state) => state.teams);
  const { leagueDetailsLoaded, leagueDetailsError, leagueDetails } = useSelector((state) => state.leagues);
  const { myId } = useSelector((state) => state.auth);
  const { gws, gwsLoaded } = useSelector((state) => state.gw);

  useEffect(() => {
    dispatch(fetchLeagueDetails(id))
  }, [dispatch, id]);

  useEffect(() => {
    if (!gwsLoaded) {
      dispatch(fetchGws());
    }
  }, [dispatch, gwsLoaded]);

  const filteredGws = gws.filter((gw) => gw.status !== 'OPEN' && gw.status !== 'SCHEDULED');
  return (
    <>
      {leagueDetailsLoaded ? 
      leagueDetailsError ? <ErrorPage /> : (
        <>
          <div class="relative overflow-x-auto">
              <h1 style={{textAlign: 'center'}}>{leagueDetails.name}</h1>
              {leagueDetails.code && <h3 style={{textAlign: 'center'}}>{t('leagues.code')}: {leagueDetails.code}</h3>}
              <table class="w-full text-sm text-left rtl:text-right text-gray-500">
                  <thead class="text-sm text-gray-700 uppercase bg-gray-200">
                      <tr>
                          <th scope="col" class="px-6 py-3">
                              Position
                          </th>
                          <th scope="col" class="px-6 py-3">
                          {t('leagues.team')}
                          </th>
                          <th scope="col" class="px-6 py-3">
                          {t('leagues.points')}
                          </th>
                          {filteredGws.map((gw) => (
                            <th key={gw.id} class="px-6 py-3">GW{gw.gw}</th>
                          ))}
                      </tr>
                  </thead>
                  <tbody>
                    {leagueDetails.rank.map((team, index) => (
                    <tr key={team.name} className={`${teamDetails.id === team.id ? 'rankHighlight' : ''}`} 
                    class="bg-white border-b text-2xl">
                      <td class="px-6 py-4">{team.rank}</td>
                      <td class="px-6 py-4 cursor-pointer hover:underline text-blue-600"  
                      onClick={() => {navigate(team.id === myId ? '/' : `/teams/${team.id}`);}}>{team.team}</td>
                      <td class="px-6 py-4">{team.points}</td>
                      {filteredGws.map((gw) => (
                        <td class="px-6 py-4 relative" className='mytooltip' 
                        key={gw.id}>{team.predictions[gw.gw] ? team.predictions[gw.gw].score : '-'}<span class={`mytooltiptext ${index<6?'bottom':'top'}`}>{team.predictions[gw.gw] && team.predictions[gw.gw].matches.length ? team.predictions[gw.gw].matches.map(item => `${item.differential ? '⭐️ ': ''}${item.captain ? '©️ ': ''}${item.data.substring(0, item.data.lastIndexOf(' -'))} (${item.data.substring(item.data.lastIndexOf(' -')+3)}) - ${item.points}`).join('\n') : t('leagues.notSentPrediction')}</span>
                        </td>
                      ))}
                    </tr>
                    ))}
                  </tbody>
              </table>
          </div>

        </>
      ) : (
        <Loading />
      )}
    </>
  )
}

export default LeagueResult;
