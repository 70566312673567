import { createAsyncThunk } from "@reduxjs/toolkit"
import ApiService from "../../services/apiService"

export const fetchLeagueDetails = createAsyncThunk('fetchLeagueDetails', async (id, { rejectWithValue }) => {
  try {
    const details = await ApiService.fetchLeagueDetails(id);
    return details;
  } catch (error) {
    if (error.response) {
      return rejectWithValue(error.response.status === 400 ? (error.response.data || error.response.status) : error.response.status);
    } else {
      return rejectWithValue(error.message);
    }
  }
})

export const createLeague = createAsyncThunk('createLeague', async (params, { rejectWithValue }) => {
  try {
    const id = await ApiService.createLeague(params);
    return id;
  } catch (error) {
    if (error.response) {
      return rejectWithValue(error.response.status === 400 ? (error.response.data || error.response.status) : error.response.status);
    } else {
      return rejectWithValue(error.message);
    }
  }
})

export const joinLeague = createAsyncThunk('joinLeague', async (code, { rejectWithValue }) => {
  try {
    const id = await ApiService.joinLeague(code);
    return id;
  } catch (error) {
    if (error.response) {
      return rejectWithValue(error.response.status === 400 ? (error.response.data || error.response.status) : error.response.status);
    } else {
      return rejectWithValue(error.message);
    }
  }
})