import axios from "axios";
import config from "../config/config";

/**
 * @typedef {object} AuthenticatedSession
 * @property {number} id User id
 * @property {number} customerId Customer id 
 * @property {string} displayname 
 * @property {string} language
 * @property {string} token
 */

/**
 * @typedef {object} User
 * @property {number} id User id
 * @property {string} displayname 
 * @property {string} [extension] Main phone number
 */

class ApiService {
  /**
   * Axios instance
   * @private
   */
  __axios;

  constructor() {
    this.__axios = axios.create({
      baseURL: config.apiUrl
    });
  }
  
  async login(params) {
    try {
      const endpoint = `auth/login`;
      const response = await this.__axios.post(endpoint, params);
      let session = response.data;
      return session
    } catch (e) {
      throw e
    }
  }

  async register(params) {
    try {
      const endpoint = `auth/register`;
      await this.__axios.post(endpoint, params);
    } catch (e) {
      throw e
    }
  }

  async passwordRecovery(params) {
    try {
      const endpoint = `auth/password-recovery`;
      await this.__axios.post(endpoint, params);
    } catch (e) {
      throw e
    }
  }

  async logout() {
    try {
      const endpoint = `auth/logout`;
      await this.__axios.post(endpoint);
    } catch (e) {
      throw e
    }
  }

  async fetchLeagueDetails(id) {
    try {
      const endpoint = `/leagues/${id}`;
      const response = await this.__axios.get(endpoint);
      /** @type {User[]} */
      let details = response.data;
      return details
    } catch (e) {
      throw e
    }
  }

  async createLeague(params) {
    try {
      const endpoint = `/leagues/`;
      const response = await this.__axios.post(endpoint, params);
      return response.data.id;
    } catch (e) {
      throw e
    }
  }

  async joinLeague(id) {
    try {
      const endpoint = `/leagues/${id}`;
      const response = await this.__axios.post(endpoint);
      return response.data.id;
    } catch (e) {
      throw e
    }
  }

  async fetchTeamDetails(id) {
    try {
      const endpoint = `/teams/${id}`;
      const response = await this.__axios.get(endpoint);
      let details = response.data;
      return details
    } catch (e) {
      throw e
    }
  }

  async editTeam(params) {
    try {
      const endpoint = `teams/${params.id}`;
      await this.__axios.post(endpoint, params.data);
    } catch (e) {
      throw e
    }
  }

  async changePassword(params) {
    try {
      const endpoint = `auth/password-change`;
      await this.__axios.post(endpoint, params);
    } catch (e) {
      throw e
    }
  }

  async fetchGws() {
    try {
      const endpoint = '/gws';
      const response = await this.__axios.get(endpoint);
      /** @type {User[]} */
      let gws = response.data;
      return gws
    } catch (e) {
      throw e
    }
  }

  async fetchGwDetails(params) {
    try {
      const endpoint = `/gws/${params.gw}/teams/${params.team}`;
      const response = await this.__axios.get(endpoint);
      let details = response.data;
      return details
    } catch (e) {
      throw e
    }
  }

  async insertGwPredictions(id, data) {
    try {
      const endpoint = `/teams/${id}/predictions`;
      await this.__axios.post(endpoint, {data});
    } catch (e) {
      throw e
    }
  }

  async fetchStaffTeamData() {
    try {
      const endpoint = `/staff/teams`;
      const response = await this.__axios.get(endpoint);
      let teams = response.data;
      return teams;
    } catch (e) {
      throw e
    }
  }

  async fetchStaffGwData(number) {
    try {
      const endpoint = `/staff/gws/${number}`;
      const response = await this.__axios.get(endpoint);
      let details = response.data;
      return details
    } catch (e) {
      throw e
    }
  }

  async saveStaffGwData(params) {
    try {
      const endpoint = `/staff/gws/${params.number}`;
      await this.__axios.post(endpoint, params.data);
    } catch (e) {
      throw e
    }
  }

  async fetchStaffGwImagesData(number) {
    try {
      const endpoint = `/staff/gws/${number}/images`;
      const response = await this.__axios.get(endpoint);
      let details = response.data;
      return details
    } catch (e) {
      throw e
    }
  }

  async fetchSettings(number) {
    try {
      const endpoint = `/settings`;
      const response = await this.__axios.get(endpoint);
      let details = response.data;
      return details
    } catch (e) {
      throw e
    }
  }
}

const apiService = new ApiService();
export default apiService; // export as singleton