import React from 'react'
import i18next from 'i18next';
import config from '../config/config.js';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function Header () {

  const { t } = useTranslation();
  const navigate = useNavigate();

  const changeLanguage = () => {
    const lang = i18next.language === 'en' ? 'it' : 'en';
    localStorage.setItem(config.localStorageLang, lang);
    i18next
      .changeLanguage(lang)
      .then((t) => {
        window.location.reload();
      });
  };

  const { logged, myDetails } = useSelector((state) => state.auth);


  return (
    <div>
      <nav class="bg-white">
          <div class="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl p-4 pl-4">
              <a href="#" class="flex items-center space-x-3 rtl:space-x-reverse  ">
                  <img src="./header.png" class="h-20 lg:h-40" alt="OWP Logo" />
              </a>
              <div class='float-right flex flex-row'>
              {!logged && (
                <>
                <button type="button" class="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 
                focus:ring-4 focus:outline-none focus:ring-blue-300  font-medium rounded-lg lg:text-xs text-sm 
                lg:px-16 px-5 py-2.5 lg:py-4 
                text-center me-2 mb-2">
                <a onClick={() => {navigate('/auth')}} class="text-xl lg:text-4xl text-white font-medium hover:text-gray-200 
                hover:no-underline">Login</a>
                </button>
                </>
              )}
              <div className='clickable' class="no-underline cursor-pointer  lg:text-6xl text-3xl 
              hover:text-gray-200 m-10" 
                onClick={() => changeLanguage()}>
                    {t('leftColumn.changeLanguage')}
              </div>
              {logged && myDetails && myDetails.staff && 
              (
                <>
                <div class="p-4 font-bold hover:underline" className='clickable' onClick={() => {navigate('/manage-gws')}}>{t('leftColumn.manageGws')}</div>
                <div class="p-4 font-bold hover:underline" className='clickable' onClick={() => {navigate('/manage-images')}}>{t('leftColumn.manageImages')}</div>
                </>
                )}
              </div>

              
          </div>
      </nav>
      <nav class="bg-gray-200">
          <div class="max-w-screen-xl mx-auto m-10 text-center items-center lg:text-center">
              <div class="flex items-center text-center justify-center">
                  <ul class="flex flex-row font-medium mt-4 ml-4 space-x-8 lg:space-x-36 rtl:space-x-reverse text-sm">
                      <li>
                      <a onClick={() => {navigate('/rules')}}
              class="cursor-pointer text-xl lg:text-5xl text-blue-800 hover:text-blue-500 hover:no-underline font-bold">{t('leftColumn.rules')}</a>
                      </li>
                      <li>
                      <a href="https://fplitalia.com/probabili-formazioni-premier-league/" 
              class="text-xl lg:text-5xl text-blue-800 hover:text-blue-500 hover:no-underline font-bold">{t('leftColumn.lineup')}</a>
                      </li>
                      <li>
                      <a href="https://fplitalia.com/statistiche-piu-in-forma-premier-league/" 
              class="text-xl lg:text-5xl text-blue-800 hover:text-blue-500 hover:no-underline font-bold">{t('leftColumn.form')}</a>
                      </li>
                      <li>
                      <a href="https://fplitalia.com/guida-prossima-giornata-premier-league/" 
              class="text-xl lg:text-5xl text-blue-800  hover:text-blue-500 hover:no-underline font-bold">{t('leftColumn.preview')}</a>
                      </li>
                  </ul>
              </div>
          </div>
      </nav>
    </div>
)
}

export default Header
