import React from "react";
import Header from "./Header";
import LeftColumn from "./LeftColumn";
import MainContent from "./MainContent";
import RightColumn from "./RightColumn";
import Footer from "./Footer";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ProtectedRoute } from '../routing/ProtectedRoute.js';
import AuthenticatorPage from "./AuthenticatorPage";
import LeagueResult from "./LeagueResult";
import RegisterPage from "./RegisterPage";
import TeamContent from "./TeamContent.js";
import PasswordRecoveryPage from "./PasswordRecoveryPage.js";
import GwsManagement from "./GwsManagement.js";
import ImagesManagement from "./ImagesManagement.js";
import RulesPage from "./RulesPage.js";

function MainPage() {  

  return (
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Header />
        <div className='owp-middle' class='mx-auto max-w-screen-xl grid lg:grid-cols-3 grid-cols-1 gap-4'>
          <div className='owp-main' class='lg:col-span-2 ml-20 m-4'> 
            <Routes>
              <Route path="/auth" element={<AuthenticatorPage />} />
              <Route path="/register" element={<RegisterPage />} />
              <Route path="/password-recovery" element={<PasswordRecoveryPage />} />
              <Route path="/rules" element={<RulesPage />} />
              <Route element={<ProtectedRoute />}>
                <Route path="/leagues/:id" element={<LeagueResult />} />
                <Route path="/" element={<MainContent />} />
                <Route path="/edit" element={<RegisterPage edit />} />
                <Route path="/teams/:id" element={<TeamContent />} />
              </Route>
              <Route element={<ProtectedRoute staff/>}>
                <Route path="/manage-gws" element={<GwsManagement />} />
                <Route path="/manage-images" element={<ImagesManagement />} />
              </Route>
            </Routes>
          </div>
          <RightColumn />
        </div>
        <Footer />
      </BrowserRouter>
  );
}

export default MainPage;