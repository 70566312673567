import React, { useState } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

function PredictionsMatch ({ isCaptain, match, onChangePrediction, onChangeCaptain}) {
  const { t } = useTranslation();
  const { data } = useSelector((state) => state.settings);
  const [value, setValue] = useState();
  return (
    <div class="text-center items-center justify-center mb-24 flex flex-col" key={match.id}>
      <div class="text-center items-center justify-center text-3xl" style={{width: '100px', textAlign: 'center', lineHeight: '39px', fontWeight: 'bold'}}>
        {match.home}-{match.away}
      </div>
      <div class="text-center items-center justify-center" style={{ width: '300px' }}>
        <Select
          options={match.form.map((player) => ({ value: player.substring(0, player.lastIndexOf('(')-1), label: player }))}
          value = {value !== undefined ? value : match.prediction ? { value: match.prediction, label: match.form.find(item => item.indexOf(match.prediction) === 0)} : null}
          onChange={(value) => {setValue(value);onChangePrediction(value)}}
        />
        {t('predictions.excluded')}: {[...(match.excluded && match.excluded.home ? match.excluded.home : []), ...(match.excluded && match.excluded.away ? match.excluded.away : [])].join(', ')}
      </div>
      <div style={{ float: 'left' }}>
        {data.captainEnabled && (
          <div style={{marginLeft: '10px', float: 'left'}}>
            <input type="checkbox" onChange={() => {onChangeCaptain(!isCaptain);}} checked={isCaptain}/>
            {t('predictions.captain')}
          </div>
        )}
      </div>
    </div>
  )
}

export default PredictionsMatch;
