import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Cookies from 'universal-cookie';
import { jwtDecode } from 'jwt-decode'

import config from "../config/config";

export const ProtectedRoute = ({ redirectPath = '/auth', children, staff }) => {
  const { logged, myDetails, recovery, unauthorizedError } = useSelector((state) => state.auth);
  const location = useLocation();
  if (unauthorizedError) {
    new Cookies().remove(config.cookieName, { path: '/' })
    return <Navigate to={redirectPath} />;
  }
  if (!logged && !new Cookies().get(config.cookieName)) {
    return <Navigate to={redirectPath} />;
  }
  if (recovery && location.pathname !== '/') {
    return <Navigate to="/" />;
  }
  if (staff && (!myDetails || !myDetails.staff)) {
    if (new Cookies().get(config.cookieName)) {
      const decoded = jwtDecode(new Cookies().get(config.cookieName));
      if(!decoded.staff) {
        return <Navigate to="/" />;
      }
    } else {
      return <Navigate to="/" />;
    }
  }
  return children ? children : <Outlet />;
};