import React, { useEffect, useRef, useState } from 'react';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Loading from '../components/Loading';
import { Form, FormGroup, Label } from 'reactstrap';
import ToastMessage from '../components/ToastMessage';
import { createLeague } from '../features/leagues/leaguesActions';
import { useNavigate } from 'react-router-dom';
import { fetchMyDetails } from '../features/teams/teamsActions';

function CreateLeagueModal ({toggle}) {
  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [errorForm, setErrorForm] = useState("");
  const [name, setName] = useState("");
  const { createLeagueLoaded, createLeagueError, createdLeagueId } = useSelector((state) => state.leagues);
  const { myId } = useSelector((state) => state.auth);
  const prevCreation = usePrevious({createLeagueLoaded, createLeagueError});
  
  const dispatch = useDispatch();

  useEffect(() => {
    if (createLeagueLoaded && !createLeagueError &&prevCreation && !prevCreation.createLeagueLoaded) {
      toggle();
      dispatch(fetchMyDetails(myId));
      navigate(`/leagues/${createdLeagueId}`);
    }
  }, [createLeagueLoaded])

  const onSubmit = (event) => {
    event.preventDefault();
    if (!name) {
      setErrorForm(t('general.missingFields'));
      return;
    }
    dispatch(createLeague({ name }));
  }

  return (
    <ReactModal
    ariaHideApp={false} isOpen style={{content: {position:'absolute', width: '400px', height: '300px', top: 'calc(50% - 150px)', left: 'calc(50% - 200px)',  transform: 'translate(-50%, -50%);'}}}>
      {prevCreation && !prevCreation.createLeagueError && createLeagueError ? <ToastMessage type="danger" text={t("leagues.errorCreate")} />: errorForm && <ToastMessage type="danger" text={errorForm} />}
      {!createLeagueLoaded ? <Loading /> : (
        <>
          <Form
              onSubmit={onSubmit}
              noValidate
            >
            <FormGroup>
              <Label for="name">{t('leagues.name')}</Label>
              <input
                type="text"
                name="name"
                id="name"
                value={name}
                onChange={(event) => setName(event.target.value)}
              />
            </FormGroup>
            <div style={{textAlign: 'center'}}>
              <button
              >{t('general.save')}</button>
              <button
                  className="back"
                  style={{marginLeft: '10px'}}
                  onClick={() => toggle()}
                >{t('general.back')}</button> 
            </div>
          </Form>
        </>
      )}
    </ReactModal>
  )
}

export default CreateLeagueModal;
