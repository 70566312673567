import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Label, FormGroup } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { authenticateUser } from '../features/auth/authActions';
import { useTranslation } from 'react-i18next';
import ToastMessage from '../components/ToastMessage';
import Loading from '../components/Loading';

const AuthenticatorPage = () => {
  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");  
  const [persistent, setPersistent] = useState(false);
  const [errorForm, setErrorForm] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, logged, error, registerOk } = useSelector((state) => state.auth);
  const prevAuth = usePrevious({error, registerOk});

  const onSubmit = (event) => {
    event.preventDefault();
    if (!email || !password) {
      setErrorForm(t('general.missingFields'));
      return;
    }
    dispatch(authenticateUser({ email, password, persistent }));
  }

  const onChangeEmail = (event) => {
    setEmail(event.target.value);
  }

  const onChangePassword = (event) => { 
    setPassword(event.target.value);
  }

  useEffect(() => {
    if (!isLoading) {
      if (logged) {
        navigate("/");
      }
    }
  }, [isLoading, logged, error, navigate])
  
  return (
    <>
      {isLoading ? <Loading /> : (
        <>
          {prevAuth && !prevAuth.error && error ? <ToastMessage type="danger" text={t("auth.errorAuth")} />: errorForm && <ToastMessage type="danger" text={errorForm} />}
          {prevAuth && !prevAuth.registerOk && registerOk && <ToastMessage type="success" text={t("auth.registrationOk")} />}
          <form class="max-w"
            onSubmit={onSubmit}
            noValidate
          >
          <div class="mb-5">
            <label for="email" class="block ml-2 lg:text-2xl font-medium text-gray-900">
              {t('auth.email')}
            </label>
            <input
              type="text"
              name="email"
              id="email"
              value={email}
              onChange={onChangeEmail}
              class="bg-blue-50 border border-gray-300 text-gray-900 text-2xl rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-1/2 p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
          <div class="mb-5">
            <label for="password" class="block ml-2 lg:text-2xl font-medium text-gray-900">
              {t('auth.password')}
            </label>
            <input
              type="password"
              name="password"
              id="password"
              value={password}
              class="bg-blue-50 border border-gray-300 text-gray-900 text-2xl rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-1/2 p-2.5  dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
              onChange={onChangePassword}
            />
          </div>
          <div class="flex items-start mb-5">
            <div class="flex items-center h-7">
              <input type="checkbox" id="persistent" name="persistent" class="w-8 h-8 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300  border-gray-600"
               onChange={() => {setPersistent(!persistent);}} checked={persistent} />
            </div>
            <label class="ms-2 text-2xl font-medium text-gray-900">
              {t('auth.persistent')}
            </label>
          </div>
          <div style={{marginTop: '10px'}}>
            <Label>{t('auth.passwordRecovery')}</Label><span className='clickable' style={{marginLeft: '5px'}} onClick={() => {navigate(`/password-recovery`);}}>{t('general.clickHere')}</span>
          </div>
          <button 
          class="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-blue-400 focus:ring-4 focus:outline-none 
          focus:ring-blue-300 font-medium rounded-2xl text-3xl sm:w-auto px-24 py-5 
          text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
            {t('auth.login')}
          </button>
        </form>
        <Label>{t('auth.notRegistered')}</Label><span className='clickable' style={{marginLeft: '5px'}} onClick={() => {navigate(`/register`);}}>{t('auth.register')}</span>
      </>
      )}
    </>
  );
};

export default AuthenticatorPage;