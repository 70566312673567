import React from 'react';
import { useTranslation } from 'react-i18next';

const RulesPage = () => {
  const { t } = useTranslation();
  return (
    <div>
    <span>{t("rules.part1")}<strong>{t("rules.part2")}</strong>{t("rules.part3")}<a href="https://fplitalia.com/regolamento-fantasy-premier-league/">{t("rules.part4")}</a>{t("rules.part5")}<br /><br />{t("rules.part6")}<strong>{t("rules.part7")}</strong>{t("rules.part8")}<strong>{t("rules.part9")}</strong>{t("rules.part10")}<em>{t("rules.part11")}</em>.</span><span>{t("rules.part12")}<br /><br />{t("rules.part13")}</span><span>{t("rules.part14")}<br /><br />{t("rules.part15")}</span><br /><br /><span>{t("rules.part16")}</span>
    </div>
  );
};

export default RulesPage;