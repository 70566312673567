import { createAsyncThunk } from '@reduxjs/toolkit'
import ApiService from '../../services/apiService'

export const fetchTeamDetails = createAsyncThunk('fetchTeamDetails', async (id, { rejectWithValue }) => {
  try {
    const details = await ApiService.fetchTeamDetails(id);
    return details;
  } catch (error) {
    if (error.response) {
      return rejectWithValue(error.response.status === 400 ? (error.response.data || error.response.status) : error.response.status);
    } else {
      return rejectWithValue(error.message);
    }
  }
})

export const fetchMyDetails = createAsyncThunk('fetchMyDetails', async (id, { rejectWithValue }) => {
  try {
    const details = await ApiService.fetchTeamDetails(id);
    return details;
  } catch (error) {
    if (error.response) {
      return rejectWithValue(error.response.status === 400 ? (error.response.data || error.response.status) : error.response.status);
    } else {
      return rejectWithValue(error.message);
    }
  }
})

export const editTeam = createAsyncThunk('editTeam', async (params, { rejectWithValue }) => {
  try {
    await ApiService.editTeam(params);
    return params;
  } catch (error) {
    if (error.response) {
      return rejectWithValue(error.response.status === 400 ? (error.response.data || error.response.status) : error.response.status);
    } else {
      return rejectWithValue(error.message);
    }
  }
})