import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logoutUser } from '../features/auth/authActions';
import i18next from 'i18next';
import config from '../config/config.js';
import ChangePwdModal from './ChangePwdModal.js';

function LeftColumn () {
  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { logged, myDetails } = useSelector((state) => state.auth);
  const [modalOpen, setModalOpen] = useState(false);
  const prevLogged = usePrevious({logged});

  const logout = () => {
    dispatch(logoutUser());
  }

  useEffect(() => {
    if (!logged && prevLogged && !prevLogged.logged) {
      navigate("/auth");
    }
  }, [logged])

  const changeLanguage = () => {
    const lang = i18next.language === 'en' ? 'it' : 'en';
    localStorage.setItem(config.localStorageLang, lang);
    i18next
      .changeLanguage(lang)
      .then((t) => {
        window.location.reload();
      });
  }

  return (
    <div className='owp-left'>
      {logged && (
        <>
          <div className='box'>
            <div className='header'>{t('leftColumn.profile')}</div>
            <div className='content'>
              <div className='clickable' onClick={() => {navigate('/')}}>{t('leftColumn.home')}</div>
              <div className='clickable' onClick={() => {navigate('/edit')}}>{t('leftColumn.edit')}</div>
              <div className='clickable' onClick={() => {setModalOpen(true)}}>{t('leftColumn.editPassword')}</div>
              <div className='clickable' onClick={() => changeLanguage()}>{t('leftColumn.changeLanguage')}</div>
              <div className='clickable' onClick={() => logout()}>{t('leftColumn.logout')}</div>
            </div>
          </div>
          {modalOpen && <ChangePwdModal edit toggle={() => setModalOpen(false)}/>}
        </>
      )}
    </div>
  )
}

export default LeftColumn
