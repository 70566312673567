import React, { useState } from 'react';
import Select from 'react-select';
import ReactModal from 'react-modal';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, FormGroup, Label } from 'reactstrap';
import ToastMessage from '../components/ToastMessage';
import moment from 'moment';

function MatchModal ({match, toggle, onSubmit}) {
  const { t } = useTranslation();
  const [errorForm, setErrorForm] = useState("");
  const [home, setHome] = useState(match ? {value: match.home, label: match.home} : null);
  const [away, setAway] = useState(match ? {value: match.away, label: match.away} : null);
  const [date, setDate] = useState(match && match.start ? moment(parseInt(match.start)).format('DD/MM/YYYY HH:mm') : "");
  const [homeExcluded, setHomeExcluded] = useState(match && match.excluded && match.excluded.home ? match.excluded.home.map((item) => {return {value: item, label: item}}) : []);
  const [awayExcluded, setAwayExcluded] = useState(match && match.excluded && match.excluded.away ? match.excluded.away.map((item) => {return {value: item, label: item}}) : []);
  const { teamData } = useSelector((state) => state.staff);

  const submit = (event) => {
    event.preventDefault();
    if (!home || !away) {
      setErrorForm(t('staff.missingMatchFields'));
      return;
    }
    if (date && !moment(date, 'DD/MM/YYYY HH:mm', true).isValid()) {
      setErrorForm(t('staff.invalidDate'));
      return;
    }
    onSubmit({home: home.value, away: away.value, start: date ? moment(date).valueOf() : null, excluded: homeExcluded.length || awayExcluded.length ? {home: homeExcluded.map((item) => item.value), away: awayExcluded.map((item) => item.value)} : null})
    toggle();
  }

  const teamOptions = [{value: null, label: ''}];
  for (let i = 0; teamData && i < Object.keys(teamData).length; i++) {
    teamOptions.push({value: Object.keys(teamData)[i], label: Object.keys(teamData)[i]});
  }

  const homeExcludedOptions = [{value: null, label: ''}];
  if (home) {
    for (let i = 0; i < teamData[home.value].length; i++) {
      homeExcludedOptions.push({value: teamData[home.value][i], label: teamData[home.value][i]});
    }
  }

  const awayExcludedOptions = [{value: null, label: ''}];
  if (away) {
    for (let i = 0; i < teamData[away.value].length; i++) {
      awayExcludedOptions.push({value: teamData[away.value][i], label: teamData[away.value][i]});
    }
  }

  return (
    <ReactModal
    ariaHideApp={false} isOpen style={{content: {position:'absolute', width: '400px', height: '400px', top: 'calc(50% - 150px)', left: 'calc(50% - 200px)',  transform: 'translate(-50%, -50%);'}}}>
      {errorForm && <ToastMessage type="danger" text={errorForm} />}
      <Form
        onSubmit={submit}
        noValidate
      >
      <FormGroup>
        <div style={{display: 'inline-block'}}>
          <div style={{ width: '100px', float: 'left' }}>
            <Select
              options={teamOptions}
              value = {home}
              onChange={(value) => {setHome(value); setHomeExcluded([]);}}
            />
          </div>
          <div style={{ width: '100px', float: 'left', marginLeft: '10px' }}>
            <Select
              options={teamOptions}
              value = {away}
              onChange={(value) => {setAway(value); setAwayExcluded([]);}}
            />
          </div>
        </div>
      </FormGroup>
      <FormGroup style={{marginTop: '10px'}}>
        <Label for="date">{t('staff.date')}</Label>
        <input
          type="text"
          name="date"
          id="date"
          value={date}
          onChange={(event) => setDate(event.target.value)}
        />
      </FormGroup>
      <FormGroup style={{marginTop: '10px'}}>
        <Label for="date">{t('staff.homeExcluded')}</Label>
        <Select
          options={homeExcludedOptions}
          value = {homeExcluded}
          onChange={(value) => {setHomeExcluded(value)}}
          isMulti
        />
      </FormGroup>
      <FormGroup style={{marginTop: '10px'}}>
        <Label for="date">{t('staff.awayExcluded')}</Label>
        <Select
          options={awayExcludedOptions}
          value = {awayExcluded}
          onChange={(value) => {setAwayExcluded(value)}}
          isMulti
        />
      </FormGroup>
      <div style={{textAlign: 'center'}}>
        <button
        >{t(match ? 'general.edit' : 'general.add')}</button>
        <button
            className="back"
            style={{marginLeft: '10px'}}
            onClick={() => toggle()}
          >{t('general.back')}</button>
      </div>
    </Form>
    </ReactModal>
  )
}

export default MatchModal;
