import { createSlice } from '@reduxjs/toolkit'
import { editTeam, fetchMyDetails, fetchTeamDetails } from './teamsActions';
import { logoutUser } from '../auth/authActions';

const initialState = {
  teamDetailsLoaded: false,
  teamDetailsError: false,
  teamDetails: {},
  editTeamLoaded: true,
  editTeamSuccess: false,
  editTeamError: null,
}

const teamsSlice = createSlice({
  name: 'teams',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchTeamDetails.pending, (state) => {
        state.teamDetailsLoaded = false
        state.teamDetailsError = false
        state.teamDetails = {}
      })
      .addCase(fetchTeamDetails.fulfilled, (state, action) => {
        state.teamDetailsLoaded = true
        state.teamDetails = action.payload
      })
      .addCase(fetchTeamDetails.rejected, (state) => {
        state.teamDetailsLoaded = true
        state.teamDetailsError = true
      })
      .addCase(fetchMyDetails.fulfilled, (state, action) => {
        state.teamDetailsLoaded = true
        state.teamDetails = action.payload
      })
      .addCase(editTeam.pending, (state) => {
        state.editTeamLoaded = false
        state.editTeamSuccess = false
        state.editTeamError = null
      })
      .addCase(editTeam.fulfilled, (state, action) => {
        state.editTeamLoaded = true
        state.editTeamSuccess = true
        if (action.payload.id === state.teamDetails.id) {  
          state.teamDetails.name = action.payload.data.name
          state.teamDetails.surname = action.payload.data.surname
          state.teamDetails.team = action.payload.data.team
        }
      })
      .addCase(editTeam.rejected, (state, action) => {
        state.editTeamLoaded = true
        state.editTeamError = action.payload
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.teamDetails = {}
        state.teamDetailsLoaded = false
      });
  },
})

export default teamsSlice.reducer;