import React, { useEffect, useRef, useState } from 'react';
import { Form, Label, FormGroup } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { registerUser } from '../features/auth/authActions';
import { fetchMyDetails, editTeam } from '../features/teams/teamsActions';
import { useTranslation } from 'react-i18next';
import Loading from '../components/Loading';
import { useNavigate } from 'react-router-dom';
import ToastMessage from '../components/ToastMessage';
import i18next from 'i18next';

const RegisterPage = ({edit}) => {
  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const { t } = useTranslation();
  const [errorForm, setErrorForm] = useState("");
  const [email, setEmail] = useState();
  const [name, setName] = useState();
  const [surname, setSurname] = useState();
  const [team, setTeam] = useState();
  const [password, setPassword] = useState("");  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, registerOk, error, myDetails, myId } = useSelector((state) => state.auth);
  const { editTeamLoaded, editTeamSuccess, editTeamError } = useSelector((state) => state.teams);
  const prevRegister = usePrevious({registerOk, error});
  const prevEdit = usePrevious({editTeamSuccess, editTeamError});

  useEffect(() => {
    if (edit && !myDetails) {
      dispatch(fetchMyDetails(myId));
    }
  }, [dispatch])

  useEffect(() => {
    if (!isLoading && !error && prevRegister && !prevRegister.registerOk && registerOk) {
      navigate("/auth");
    }
  }, [isLoading, registerOk, error, navigate])

  const onSubmit = (event) => {
    event.preventDefault();
    const _email = arrangeEmailValue();
    const _name = arrangeNameValue();
    const _surname = arrangeSurnameValue();
    const _team = arrangeTeamValue();

    if (!_email || !_name || !_surname || !_team || (!edit && !password)) {
      setErrorForm(t('auth.missingFieldsRegister'));
      return;
    }
    if (!_email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )) {
      setErrorForm(t('auth.wrongEmailRegister'));    
      return;  
    }
    if (!edit && (password.length < 8 ||
      !/\d/.test(password) ||
      !/[a-z]/.test(password) ||
      !/[A-Z]/.test(password) ||
      !/\W|_/.test(password))) {
      setErrorForm(t('auth.wrongPassword'));    
      return;  
    }
    if (edit) {
      dispatch(editTeam({id: myId, data: { email: _email, name: _name, surname: _surname, team: _team }}));
    } else {
      dispatch(registerUser({ email: _email, password, name: _name, surname: _surname, team: _team, lang: i18next.language }));
    }
  }

  const onChangeEmail = (event) => {
    setEmail(event.target.value);
  }  

  const onChangePassword = (event) => { 
    setPassword(event.target.value);
  }

  const onChangeName = (event) => { 
    setName(event.target.value);
  }

  const onChangeSurname = (event) => { 
    setSurname(event.target.value);
  }

  const onChangeTeam = (event) => { 
    setTeam(event.target.value);
  }



  const arrangeEmailValue = () => {
    if (!edit || email !== undefined) {
      return email;
    }
    if (email !== undefined) {
      return email;
    }
    return myDetails.email;
  }

  const arrangeNameValue = () => {
    if (!edit || name !== undefined) {
      return name;
    }
    if (name !== undefined) {
      return name;
    }
    return myDetails.name;
  }

  const arrangeSurnameValue = () => {
    if (!edit || surname !== undefined) {
      return surname;
    }
    if (surname !== undefined) {
      return surname;
    }
    return myDetails.surname;
  }

  const arrangeTeamValue = () => {
    if (!edit || team !== undefined) {
      return team;
    }
    if (team !== undefined) {
      return team;
    }
    return myDetails.team;
  }

  return (
    <>
      {(isLoading || !editTeamLoaded || (edit && !myDetails)) ? <Loading /> : (
        <>
          {error && prevRegister && prevRegister.error !== error ? <ToastMessage type="danger" text={error === 'EXISTING_EMAIL' ? t('general.existingEmailError') : t('auth.errorRegister')} /> : errorForm && <ToastMessage type="danger" text={errorForm} />}
          {editTeamError && prevEdit && editTeamError!==prevEdit.editTeamError && <ToastMessage type="danger" text={editTeamError === 'EXISTING_EMAIL' ? t('general.existingEmailError') : t('general.errorSave')} />}
          {editTeamSuccess && prevEdit && !prevEdit.editTeamSuccess && <ToastMessage type="success" text={t('general.saved')} />}

          <form class="max-w" onSubmit={onSubmit} noValidate>
            <div class="mb-5">
              <label for="name" 
              class="block ml-2 lg:text-2xl font-medium text-gray-900">{t('auth.name')}
              </label>
              <input type="text" id="name" name="name"
              class="bg-blue-50 border border-gray-300 text-gray-900 text-2xl rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-1/2 p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500" 
              value={arrangeNameValue()}
              onChange={onChangeName}             
              />
            </div>
            <div class="mb-5">
              <label for="surname" 
              class="block ml-2 lg:text-2xl font-medium text-gray-900">{t('auth.surname')}
              </label>
              <input type="text" id="surname" name="surname"
              class="bg-blue-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-1/2 p-2.5  dark:border-gray-600  dark:focus:ring-blue-500 dark:focus:border-blue-500" 
              value={arrangeSurnameValue()}
              onChange={onChangeSurname}             
              />
            </div>
            <div class="mb-5">
              <label for="email" 
              class="block ml-2 text-2xl font-medium text-gray-900">{t('auth.email')}
              </label>
              <input type="text" id="email" name="email"
              class="bg-blue-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-1/2 p-2.5  dark:border-gray-600  dark:focus:ring-blue-500 dark:focus:border-blue-500" 
              value={arrangeEmailValue()}
              onChange={onChangeEmail}             
              />
            </div>
            <div class="mb-5">
              <label for="team" 
              class="block ml-2 text-2xl font-medium text-gray-900">{t('auth.team')}
              </label>
              <input type="text" id="team" name="team"
              class="bg-blue-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-1/2 p-2.5  dark:border-gray-600  dark:focus:ring-blue-500 dark:focus:border-blue-500" 
              value={arrangeTeamValue()}
              onChange={onChangeTeam}             
              />
            </div>
            {!edit && (
            <div class="mb-5">
              <label for="password" 
              class="block ml-2  text-2xl font-medium text-gray-900">{t('auth.password')}
              </label>
              <input type="password" id="password" name="password"
              class="bg-blue-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-1/2 p-2.5  dark:border-gray-600  dark:focus:ring-blue-500 dark:focus:border-blue-500" 
              value={password}
              onChange={onChangePassword}
              />
            </div>  
            )}
            <button 
            type="submit" 
            class="lg:mr-2 text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-blue-400 focus:ring-4 focus:outline-none 
              focus:ring-blue-300 font-medium rounded-2xl text-3xl lg:w-auto sm:w-1/2 px-24 py-5 
              text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
              {t(edit ? 'general.save' : 'auth.register')}
            </button>
            <button class="text-black bg-white hover:bg-blue-400 focus:ring-4 focus:outline-none 
              focus:ring-blue-300 font-medium rounded-2xl text-3xl lg:w-auto sm:w-1/2 px-24 py-5 
              text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" 
              onClick={() => navigate('/auth')}>{t('general.back')}</button>
          </form>
        </>
      )}
    </>
  );
};

export default RegisterPage;