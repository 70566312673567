import { createAsyncThunk } from '@reduxjs/toolkit'
import ApiService from '../../services/apiService'

export const fetchStaffTeamData = createAsyncThunk('fetchStaffTeamData', async (number, { rejectWithValue }) => {
  try {
    const teamData = await ApiService.fetchStaffTeamData();
    return teamData;
  } catch (error) {
    if (error.response) {
      return rejectWithValue(error.response.status === 400 ? (error.response.data || error.response.status) : error.response.status);
    } else {
      return rejectWithValue(error.message);
    }
  }
})

export const fetchStaffGwData = createAsyncThunk('fetchStaffGwData', async (number, { rejectWithValue }) => {
  try {
    const gwData = await ApiService.fetchStaffGwData(number);
    return gwData;
  } catch (error) {
    if (error.response) {
      return rejectWithValue(error.response.status === 400 ? (error.response.data || error.response.status) : error.response.status);
    } else {
      return rejectWithValue(error.message);
    }
  }
})

export const saveStaffGwData = createAsyncThunk('saveStaffGwData', async (params, { rejectWithValue }) => {
  try {
    await ApiService.saveStaffGwData(params);
  } catch (error) {
    if (error.response) {
      return rejectWithValue(error.response.status === 400 ? (error.response.data || error.response.status) : error.response.status);
    } else {
      return rejectWithValue(error.message);
    }
  }
})

export const fetchStaffImagesData = createAsyncThunk('fetchStaffImagesData', async (number, { rejectWithValue }) => {
  try {
    const gwData = await ApiService.fetchStaffGwImagesData(number);
    return gwData;
  } catch (error) {
    if (error.response) {
      return rejectWithValue(error.response.status === 400 ? (error.response.data || error.response.status) : error.response.status);
    } else {
      return rejectWithValue(error.message);
    }
  }
})