import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { fetchMyDetails } from '../features/teams/teamsActions';
import CreateLeagueModal from './CreateLeagueModal';
import JoinLeagueModal from './JoinLeagueModal';
import { fetchSettings } from '../features/settings/settingsActions';

function RightColumn () {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [createLeagueModalOpen, setCreateLeagueModalOpen] = useState(false);
  const [joinLeagueModalOpen, setJoinLeagueModalOpen] = useState(false);
  const { logged, myId } = useSelector((state) => state.auth);
  const { teamDetailsLoaded, teamDetailsError, teamDetails } = useSelector((state) => state.teams);
  const { data } = useSelector((state) => state.settings);

  useEffect(() => {
    if (logged && !teamDetailsLoaded) {
      dispatch(fetchMyDetails(myId));
    }
  }, [dispatch, logged]);

  useEffect(() => {
    if (logged && !data) {
      dispatch(fetchSettings());
    }
  }, [dispatch, logged]);
  
  return (
    <div className='owp-right' class="lg:col-span-1">
      {logged && (
        <>
          {teamDetailsLoaded && (
            <div class="block items-center max-w p-4 m-4 mt-20 bg-white border border-gray-200 rounded-lg 
            shadow hover:bg-gray-100 hover:no-underline bg-gradient-to-b from-blue-400 to-white">
              <h5 class="text-2xl -mt-2 font-normal tracking-tight text-gray-700">
              <span>{teamDetails.name}</span> <span>{teamDetails.surname}</span>
              </h5>
              <h5 class="mb-8 -mt-4 text-4xl font-bold tracking-tight text-gray-700">
              {teamDetails.team}
              </h5>
              <div class="flow-root justify-between text-gray-700">
                <p class="float-left">{t('team.points')}</p>
                <p class="float-right">{teamDetails.points || 0}</p>            
              </div>
            </div>
          )}
          <div class="block items-center max-w p-4 m-4 bg-white border border-gray-200 rounded-lg shadow 
          hover:bg-gray-100 bg-gradient-to-b from-blue-200 to-white">
            {teamDetails && teamDetails.leagues && teamDetails.leagues.map((league) => (
              <div className='clickable'  key={league.id} onClick={() => {navigate(`/leagues/${league.id}`);}}>
                <div style={{padding: '3px'}}><span style={{fontWeight: 'bold'}}>{league.name}</span><span style={{float: 'right'}}>{league.rank}</span></div>
              </div>
            ))}
            {teamDetails && teamDetails.id === myId && <div style={{padding: '3px'}} className='clickable' onClick={() => {setCreateLeagueModalOpen(true)}}>{t('leagues.create')}</div>}
            {teamDetails && teamDetails.id === myId && <div style={{padding: '3px'}} className='clickable' onClick={() => {setJoinLeagueModalOpen(true)}}>{t('leagues.join')}</div>}
          </div>
          {createLeagueModalOpen && <CreateLeagueModal toggle={() => setCreateLeagueModalOpen(!createLeagueModalOpen)}/>}
          {joinLeagueModalOpen && <JoinLeagueModal toggle={() => setJoinLeagueModalOpen(!joinLeagueModalOpen)}/>}
        </>
      )}      
    </div>
  )
}

export default RightColumn
