import { createSlice } from "@reduxjs/toolkit"
import { authenticateUser, changePassword, logoutUser, notAuthenticated, passwordRecovery, registerUser } from "./authActions"
import { fetchMyDetails, fetchTeamDetails, editTeam } from "../teams/teamsActions"

const initialState = {
  isLoading: false,
  registerOk: false,
  error: null,
  logged: false,
  myId: null,
  passwordRecoveryOk: false,
  passwordRecoverLoading: false,
  passwordRecoveryError: false,
  recovery: false,
  changePasswordOk: false,
  changePasswordError: false,
  changePasswordLoading: false,
  myDetails: null,
  unauthorizedError: false
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state, action) => {
      state.isLoading = false
      state.logged = false
      state.error = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(authenticateUser.pending, (state) => {
        state.isLoading = true
        state.error = null
        state.logged = false
        state.registerOk = false
        state.unauthorizedError = false
      })
      .addCase(authenticateUser.fulfilled, (state, action) => {
        state.isLoading = false
        state.logged = true
        state.myId = action.payload.id
        state.recovery = action.payload.recovery
      })
      .addCase(authenticateUser.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.payload
      })
      .addCase(registerUser.pending, (state) => {
        state.isLoading = true
        state.registerOk = false
        state.error = null
      })
      .addCase(registerUser.fulfilled, (state) => {
        state.isLoading = false
        state.registerOk = true
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.payload
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.logged = false
        state.myDetails = null
      })
      .addCase(passwordRecovery.pending, (state) => {
        state.passwordRecoveryLoading = true
        state.passwordRecoveryOk = false
        state.passwordRecoveryError = false
      })
      .addCase(passwordRecovery.fulfilled, (state) => {
        state.passwordRecoveryLoading = false
        state.passwordRecoveryOk = true
      })
      .addCase(passwordRecovery.rejected, (state, action) => {
        state.passwordRecoveryLoading = false
        state.passwordRecoveryError = true
      })
      .addCase(changePassword.pending, (state) => {
        state.changePasswordLoading = true
        state.changePasswordOk = false
        state.changePasswordError = false
      })
      .addCase(changePassword.fulfilled, (state) => {
        state.changePasswordLoading = false
        state.changePasswordOk = true
        state.recovery = false
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.changePasswordLoading = false
        state.changePasswordError = true
      })
      .addCase(fetchTeamDetails.fulfilled, (state, action) => {
        if (action.payload.id === state.myId) {
          state.recovery = action.payload.recovery
        }
      })
      .addCase(fetchMyDetails.fulfilled, (state, action) => {
        state.myDetails = action.payload
      })
      .addCase(editTeam.fulfilled, (state, action) => {
        state.myDetails = action.payload.data
      })
      .addCase(notAuthenticated.fulfilled, (state, action) => {
        state.unauthorizedError = true
        state.logged = false
      })
  },
})

export const { logout } = authSlice.actions;
export default authSlice.reducer;