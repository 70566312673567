import { createAsyncThunk } from "@reduxjs/toolkit"
import ApiService from "../../services/apiService"

export const notAuthenticated = createAsyncThunk('auth/notAuthenticated', async (params, { rejectWithValue }) => {
});

export const authenticateUser = createAsyncThunk('auth/login', async (params, { rejectWithValue }) => {
  try {
    const id = await ApiService.login(params);
    return id;
  } catch (error) {
    if (error.response) {
      return rejectWithValue(error.response.status === 400 ? (error.response.data || error.response.status) : error.response.status);
    } else {
      return rejectWithValue(error.message);
    }
  }
})

export const registerUser = createAsyncThunk('auth/register', async (params, { rejectWithValue }) => {
  try {
    const session = await ApiService.register(params);
    return session;
  } catch (error) {
    if (error.response) {
      return rejectWithValue(error.response.status === 400 ? (error.response.data || error.response.status) : error.response.status);
    } else {
      return rejectWithValue(error.message);
    }
  }
})

export const passwordRecovery = createAsyncThunk('auth/passwordRecovery', async (params, { rejectWithValue }) => {
  try {
    await ApiService.passwordRecovery(params);
  } catch (error) {
    if (error.response) {
      return rejectWithValue(error.response.status === 400 ? (error.response.data || error.response.status) : error.response.status);
    } else {
      return rejectWithValue(error.message);
    }
  }
})

export const changePassword = createAsyncThunk('auth/passwordChange', async (params, { rejectWithValue }) => {
  try {
    await ApiService.changePassword(params);
  } catch (error) {
    if (error.response) {
      return rejectWithValue(error.response.status === 400 ? (error.response.data || error.response.status) : error.response.status);
    } else {
      return rejectWithValue(error.message);
    }
  }
})

export const logoutUser = createAsyncThunk('auth/logout', async (params, { rejectWithValue }) => {
  try {
    await ApiService.logout();
  } catch (error) {
    if (error.response) {
      return rejectWithValue(error.response.status === 400 ? (error.response.data || error.response.status) : error.response.status);
    } else {
      return rejectWithValue(error.message);
    }
  }
})