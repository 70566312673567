import { createAsyncThunk } from "@reduxjs/toolkit"
import ApiService from "../../services/apiService"

export const fetchGws = createAsyncThunk('fetchGws', async (params, { rejectWithValue }) => {
  try {
    const gws = await ApiService.fetchGws();
    return gws;
  } catch (error) {
    if (error.response) {
      return rejectWithValue(error.response.status === 400 ? (error.response.data || error.response.status) : error.response.status);
    } else {
      return rejectWithValue(error.message);
    }
  }
})

export const fetchGwDetails = createAsyncThunk('fetchGwDetails', async (params, { rejectWithValue }) => {
  try {
    const details = await ApiService.fetchGwDetails(params);
    return details;
  } catch (error) {
    if (error.response) {
      return rejectWithValue(error.response.status === 400 ? (error.response.data || error.response.status) : error.response.status);
    } else {
      return rejectWithValue(error.message);
    }
  }
})

export const insertGwPredictions = createAsyncThunk('insertGwPredictions', async (payload, { rejectWithValue }) => {
  try {
    await ApiService.insertGwPredictions(payload.id, payload.data);
    return payload.data;
  } catch (error) {
    if (error.response) {
      return rejectWithValue(error.response.status === 400 ? (error.response.data || error.response.status) : error.response.status);
    } else {
      return rejectWithValue(error.message);
    }
  }
})