import React, { useEffect, useRef, useState } from 'react';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Loading from '../components/Loading';
import { Form, FormGroup, Label } from 'reactstrap';
import ToastMessage from '../components/ToastMessage';
import { joinLeague } from '../features/leagues/leaguesActions';
import { useNavigate } from 'react-router-dom';
import { fetchMyDetails } from '../features/teams/teamsActions';

function JoinLeagueModal ({toggle}) {
  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [errorForm, setErrorForm] = useState("");
  const [code, setCode] = useState("");
  const { joinLeagueLoaded, joinLeagueError, joinedLeagueId } = useSelector((state) => state.leagues);
  const { myId } = useSelector((state) => state.auth);
  const prevJoin = usePrevious({joinLeagueLoaded, joinLeagueError});
  
  const dispatch = useDispatch();

  useEffect(() => {
    if (joinLeagueLoaded && !joinLeagueError &&prevJoin && !prevJoin.createLeagueLoaded) {
      toggle();
      dispatch(fetchMyDetails(myId));
      navigate(`/leagues/${joinedLeagueId}`);
    }
  }, [joinLeagueLoaded])

  const onSubmit = (event) => {
    event.preventDefault();
    if (!code) {
      setErrorForm(t('general.missingFields'));
      return;
    }
    dispatch(joinLeague(code));
  }

  return (
    <ReactModal
    ariaHideApp={false} isOpen style={{content: {position:'absolute', width: '400px', height: '300px', top: 'calc(50% - 150px)', left: 'calc(50% - 200px)',  transform: 'translate(-50%, -50%);'}}}>
      {prevJoin && !prevJoin.joinLeagueError && joinLeagueError ? <ToastMessage type="danger" text={joinLeagueError === "NOT_EXISTING" ? t("leagues.notExistingLeague") : t("leagues.errorJoin")} />: errorForm && <ToastMessage type="danger" text={errorForm} />}
      {!joinLeagueLoaded ? <Loading /> : (
        <>
          <Form
              onSubmit={onSubmit}
              noValidate
            >
            <FormGroup>
              <Label for="code">{t('leagues.code')}</Label>
              <input
                type="text"
                name="code"
                id="code"
                value={code}
                onChange={(event) => setCode(event.target.value)}
              />
            </FormGroup>
            <div style={{textAlign: 'center'}}>
              <button
              >{t('leagues.joinButton')}</button>
              <button
                  className="back"
                  style={{marginLeft: '10px'}}
                  onClick={() => toggle()}
                >{t('general.back')}</button>
            </div>
          </Form>
        </>
      )}
    </ReactModal>
  )
}

export default JoinLeagueModal;
