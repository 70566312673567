import React from "react";

const style = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '85%',
};

const Loading = (props) => (
  <div className="container-fluid" style={{ ...style, ...props.style }}>
    <img src={`/spinner.svg`} alt="Loading..." />
  </div>
);

export default Loading;
