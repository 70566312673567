import React, { useEffect } from 'react';
import MainPage from './pages/MainPage';
import Cookies from 'universal-cookie';
import config from "./config/config";
import { useDispatch } from 'react-redux';
import { authenticateUser } from './features/auth/authActions';
import { jwtDecode } from 'jwt-decode'
import './bootstrap/css/bootstrap.min.css';

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    if (new Cookies().get(config.cookieName)) {
      const decoded = jwtDecode(new Cookies().get(config.cookieName));
      dispatch(authenticateUser.fulfilled({id: decoded.idTeam}));
    }
  }, [dispatch]);

  return (
    <React.Fragment>
      <MainPage />
    </React.Fragment>
  );
}

export default App;
