import React from 'react';
import { useTranslation } from 'react-i18next';

const ErrorPage = () => {
  const { t } = useTranslation();

  return (
    <div className="p-4 rounded bg-white text-center h-100 w-100">
       <img src='/header.png'
        alt="Error"
        height="40%"
        style={{ maxHeight: '20rem' }}
      />
      <div className={`alert alert-danger`} role="alert">
        {t('general.errorPage')}
      </div>
    </div>
  );
};

export default ErrorPage;