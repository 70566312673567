import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Loading from '../components/Loading';
import { fetchMyDetails, fetchTeamDetails } from '../features/teams/teamsActions';
import { fetchGwDetails } from '../features/gw/gwActions';
import PredictionsResult from './PredictionsResult';
import ErrorPage from './ErrorPage';

function TeamContent () {
  const { t } = useTranslation();
  let { id } = useParams();
  const dispatch = useDispatch();
  const { gwDetailsLoaded, gwDetails, gwDetailsError, gws } = useSelector((state) => state.gw);
  const { myId } = useSelector((state) => state.auth);
  const lastGw = gws.find((gw) => gw.status === 'PENDING' || gw.status === 'FINISHED');
    
  useEffect(() => {
    if (id === myId) {
      dispatch(fetchMyDetails(id));
    } else {
      dispatch(fetchTeamDetails(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (lastGw) {
      dispatch(fetchGwDetails({gw: lastGw.id, team: id}));
    }
  }, [dispatch]);
  return (
    <>
      {lastGw ? (
        gwDetailsLoaded ? gwDetailsError ? <ErrorPage /> : (
          <>
            <h1 style={{textAlign: 'center'}}>{t("general.gw")} {gwDetails.gw}</h1>
            <PredictionsResult gwDetails={gwDetails} />
          </>
        ) : <Loading />
      ) : (
        <h4>{t("team.noGW")}</h4>
      )}
    </>
  )
}

export default TeamContent;
