import { createSlice } from '@reduxjs/toolkit'
import { fetchStaffGwData, fetchStaffImagesData, fetchStaffTeamData, saveStaffGwData } from './staffActions';

const initialState = {
  gwDataLoaded: true,
  gwDataError: false,
  gwData: {},
  gwDataSaved: true,
  gwDataSavedSuccess: false,
  gwDataSavedError: null,
  teamData: null,
  gwDataImagesLoaded: true,
  gwDataImagesError: false,
  gwDataImages: {},
}

const staffSlice = createSlice({
  name: 'staff',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchStaffGwData.pending, (state) => {
        state.gwDataLoaded = false
        state.gwDataError = false
        state.gwData = null
      })
      .addCase(fetchStaffGwData.fulfilled, (state, action) => {
        state.gwDataLoaded = true
        state.gwData = action.payload
      })
      .addCase(fetchStaffGwData.rejected, (state) => {
        state.gwDataLoaded = true
        state.gwDataError = true
      })
      .addCase(saveStaffGwData.pending, (state) => {
        state.gwDataSaved = false
        state.gwDataSavedSuccess = false
        state.gwDataSavedError = null
      })
      .addCase(saveStaffGwData.fulfilled, (state) => {
        state.gwDataSaved = true
        state.gwDataSavedSuccess = true
      })
      .addCase(saveStaffGwData.rejected, (state, action) => {
        state.gwDataSaved = true
        state.gwDataSavedError = action.payload
      })
      .addCase(fetchStaffTeamData.fulfilled, (state, action) => {
        state.teamData = action.payload
      })
      .addCase(fetchStaffImagesData.pending, (state) => {
        state.gwDataImagesLoaded = false
        state.gwDataImagesError = false
        state.gwDataImages = null
      })
      .addCase(fetchStaffImagesData.fulfilled, (state, action) => {
        state.gwDataImagesLoaded = true
        state.gwDataImages = action.payload
      })
      .addCase(fetchStaffImagesData.rejected, (state) => {
        state.gwDataImagesLoaded = true
        state.gwDataImagesError = true
      });
  },
})

export default staffSlice.reducer;