import React, { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { fetchStaffGwData, fetchStaffImagesData, saveStaffGwData } from '../features/staff/staffActions';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../components/Loading';
import ErrorPage from './ErrorPage';
import GwsMatchManagement from './GwsMatchManagement';
import ToastMessage from '../components/ToastMessage';
import MatchModal from './MatchModal';

function ImagesManagement () {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { gwDataImagesLoaded, gwDataImagesError, gwDataImages } = useSelector((state) => state.staff);
  const [gw, setGw] = useState(null);
  const gwOptions = [{value: null, label: ''}];
  for (let i = 1; i <= 38; i++) {
    gwOptions.push({value: i, label: `${t('general.gw')} ${i}`});
  }

  const changeGw = (value) => {
    setGw(value);
    if (value.value) {
      dispatch(fetchStaffImagesData(value.value));
    }
  }

  const test = () => {
    let html = `<html>\n<head><link href='https://fonts.googleapis.com/css?family=Montserrat' rel='stylesheet'><style>body {font-family: 'Montserrat';color:rgb(27,58,109);}</style>\n</head><script src="https://html2canvas.hertzen.com/dist/html2canvas.min.js"></script><script>`;
    html+=`function createImages() {document.getElementById("imageButton").style.display='none';for(let i=0;i<3;i+=1){html2canvas(document.querySelector("#total"+i)).then(canvas => {document.querySelector("#total"+i).innerHTML = "";document.querySelector("#total"+i).appendChild(canvas)});}}`;
    html+='</script><body><button id="imageButton" style="margin-bottom:20px" onClick="createImages()">Genera immagini</button>\n';
    if (gwDataImages && gwDataImages.or && gwDataImages.or.length>0) {
      html+= `<div style="position: relative;width:1080px;" id="total0"><img src="/images/or.png" alt="or">`;
      for (let i=0; i<gwDataImages.or.length; i+=1) {
        html+=`<div style="position: absolute; left: 55px; top:${684+i*116}px; width:655px;text-align:center; font-weight:bold; font-size:50px;">${gwDataImages.or[i].team}</div>`;
        html+=`<div style="position: absolute; left: 780px; top:${684+i*116}px; width:245px;text-align:center; font-weight:bold; font-size:50px;">${gwDataImages.or[i].points}</div>`;
      }
      html+='</div>';
    }
    if (gwDataImages && gwDataImages.gr && gwDataImages.gr.length>0) {
      html+= `<div style="position: relative;width:1080px;" id="total1"><img src="/images/gr.png" alt="gr">`;
      for (let i=0; i<gwDataImages.gr.length; i+=1) {
        html+=`<div style="position: absolute; left: 55px; top:${684+i*116}px; width:655px;text-align:center; font-weight:bold; font-size:50px;">${gwDataImages.gr[i].team}</div>`;
        html+=`<div style="position: absolute; left: 780px; top:${684+i*116}px; width:245px;text-align:center; font-weight:bold; font-size:50px;">${gwDataImages.gr[i].points}</div>`;
      }
      html+='</div>';
    }
    if (gwDataImages && gwDataImages.picks && gwDataImages.gr.length>0) {
      html+= `<div style="position: relative;width:1080px;" id="total2"><img src="/images/picks.png" alt="picks">`;
      for (let i=0; i<gwDataImages.picks.length; i+=1) {
        html+=`<div style="position: absolute; left: 161px; top:${665+i*88}px; width:502px;text-align:center; font-weight:bold; font-size:50px;">${gwDataImages.picks[i].choice.substring(0, gwDataImages.picks[i].choice.lastIndexOf(' -'))}</div>`;
        html+=`<div style="position: absolute; left: 717px; top:${665+i*88}px; width:188px;text-align:center; font-weight:bold; font-size:50px;">${gwDataImages.picks[i].score}</div>`;
      }
      html+='</div>';
    }
    html+='</body></html>';
    var newWindow = window.open();
    newWindow.document.write(html);
  }

  return (
    <>
      <div>
        <div style={{ width: '300px' }}>
          <Select
            options={gwOptions}
            value = {gw}
            onChange={(value) => {changeGw(value)}}
          />
        </div>
        {!gwDataImagesLoaded ? <Loading /> : (
          <>
            {gwDataImagesError ? <ErrorPage /> : (
              <>
                {gwDataImages && <button onClick={test}>{t("staff.openImages")}</button>}
              </>
            )}
          </>
        )}
      </div>
    </>
  )
}

export default ImagesManagement;
