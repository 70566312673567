import { createAsyncThunk } from '@reduxjs/toolkit'
import ApiService from '../../services/apiService'

export const fetchSettings = createAsyncThunk('fetchSettings', async (number, { rejectWithValue }) => {
  try {
    const settings = await ApiService.fetchSettings();
    return settings;
  } catch (error) {
    if (error.response) {
      return rejectWithValue(error.response.status === 400 ? (error.response.data || error.response.status) : error.response.status);
    } else {
      return rejectWithValue(error.message);
    }
  }
})