import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import config from './config/config.js';

const language = (localStorage.getItem(config.localStorageLang) || (navigator.language || navigator.userLanguage).substring(0, (navigator.language || navigator.userLanguage).indexOf('-'))) === 'it' ? 'it' : 'en';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: `${process.env.PUBLIC_URL}/locales/${language}/translation.json`
    },
    lng: language,
    //fallbackLng: 'en',
    debug: config.debug,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });
