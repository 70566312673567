import { logout } from "./authSlice";
import { notAuthenticated } from "./authActions";

const authMiddleware = () => (next) => async (action) => {
  if (action && action.type.endsWith('rejected') && action.payload) {
    if (action.payload === 403) {
      next(logout(action.payload));
      return;
    }
    if (action.payload === 401) {
      next(notAuthenticated.fulfilled());
      return;
    }
  }

  return next(action);
};

export default authMiddleware;