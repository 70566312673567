import React, { useEffect, useRef, useState } from 'react';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Loading from '../components/Loading';
import { Form, FormGroup, Label } from 'reactstrap';
import ToastMessage from '../components/ToastMessage';
import { changePassword } from '../features/auth/authActions';

function ChangePwdModal ({edit, toggle}) {
  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const { t } = useTranslation();
  const [errorForm, setErrorForm] = useState("");
  const [modalOpen, setModalOpen] = useState(true);
  const [oldPwd, setOldPwd] = useState("");
  const [newPwd, setNewPwd] = useState("");
  const [newPwd2, setNewPwd2] = useState("");
  const { changePasswordOk, changePasswordLoading, changePasswordError } = useSelector((state) => state.auth);
  const prevChange = usePrevious({changePasswordOk, changePasswordError});
  
  const dispatch = useDispatch();

  useEffect(() => {
    if (changePasswordOk && prevChange && !prevChange.changePasswordOk) {
      setModalOpen(false);
    }
  }, [changePasswordOk])

  const onSubmit = (event) => {
    event.preventDefault();
    if (!oldPwd || !newPwd || !newPwd2) {
      setErrorForm(t('general.missingFields'));
      return;
    }
    if (newPwd !== newPwd2) {
      setErrorForm(t('auth.pwdMismatch'));
      return;
    }
    if (newPwd.length < 8 ||
      !/\d/.test(newPwd) ||
      !/[a-z]/.test(newPwd) ||
      !/[A-Z]/.test(newPwd) ||
      !/\W|_/.test(newPwd)) {
      setErrorForm(t('auth.wrongPassword'));    
      return;  
    }
    dispatch(changePassword({ oldPassword: oldPwd, newPassword: newPwd }));
  }

  return (
    <ReactModal
    ariaHideApp={false} isOpen={modalOpen} style={{content: {position:'absolute', width: '400px', height: '300px', top: 'calc(50% - 150px)', left: 'calc(50% - 200px)',  transform: 'translate(-50%, -50%);'}}}>
      {prevChange && !prevChange.changePasswordOk && changePasswordOk && <ToastMessage type="success" text={t("auth.passwordChanged")} />}
      {prevChange && !prevChange.changePasswordError && changePasswordError ? <ToastMessage type="danger" text={t("auth.errorPasswordChange")} />: errorForm && <ToastMessage type="danger" text={errorForm} />}
      {changePasswordLoading ? <Loading /> : (
        <>
          {!edit && <Label>{t('auth.recoveryChange')}</Label>}
          <Form
              onSubmit={onSubmit}
              noValidate
            >
            <FormGroup>
              <Label for="oldPwd">{t('auth.oldPwd')}</Label>
              <input
                type="password"
                name="oldPwd"
                id="oldPwd"
                value={oldPwd}
                onChange={(event) => setOldPwd(event.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="newPwd">{t('auth.newPwd')} *</Label>
              <input
                type="password"
                name="newPwd"
                id="newPwd"
                value={newPwd}
                onChange={(event) => setNewPwd(event.target.value)}
              />
            </FormGroup>

            <FormGroup>
              <Label for="newPwd2">{t('auth.newPwd2')} *</Label>
              <input
                type="password"
                name="newPwd2"
                id="newPwd2"
                value={newPwd2}
                onChange={(event) => setNewPwd2(event.target.value)}
              />
            </FormGroup>
            <div>* {t('general.passwordConstraints')}</div>
            <div style={{textAlign: 'center'}}>
              <button
              >{t('general.save')}</button>
              {edit && (
                <button
                  className="back"
                  style={{marginLeft: '10px'}}
                  onClick={() => toggle ? toggle() : setModalOpen(false)}
                >{t('general.back')}</button>              
              )}
            </div>
          </Form>
        </>
      )}
    </ReactModal>
  )
}

export default ChangePwdModal
