import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchGwDetails, fetchGws } from "../features/gw/gwActions";
import PredictionsResult from './PredictionsResult';
import PredictionsEdit from './PredictionsEdit';
import { useTranslation } from 'react-i18next';
import Loading from '../components/Loading';
import ChangePwdModal from './ChangePwdModal';
import ErrorPage from './ErrorPage';
import { fetchMyDetails } from '../features/teams/teamsActions';
import { fetchStaffTeamData } from '../features/staff/staffActions';

function MainContent () {
  const { t } = useTranslation();
  
  const dispatch = useDispatch();
  const { gwsLoaded, gwDetails, gwDetailsError, gws } = useSelector((state) => state.gw);
  const { teamDetails } = useSelector((state) => state.teams);
  const { myId, recovery, myDetails } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!gwsLoaded) {
      dispatch(fetchGws());
    }
  }, [dispatch]);

  useEffect(() => {
    if (myDetails && myDetails.staff) {
      dispatch(fetchStaffTeamData());
    }
  }, [dispatch, myDetails]);

  useEffect(() => {
  if (gwsLoaded && (!gwDetails || gwDetails.team !== myId)) {
    const currentGw = gws.find((gw) => gw.status !== 'SCHEDULED');
    dispatch(fetchGwDetails({gw: currentGw ? currentGw.id : gws[gws.length - 1].id, team: myId}));
  }
}, [dispatch, gwsLoaded, gwDetails]);

  useEffect(() => {
    if(teamDetails && myId && teamDetails.id !== myId) {
      dispatch(fetchMyDetails(myId));
    }
  }, [dispatch, teamDetails, myId]);

  return (
    <>     
    {recovery ? (
      <ChangePwdModal />
    ) : 
      gwDetailsError ? <ErrorPage /> : (
    gwDetails ? (
      <div>
        <h1 style={{textAlign: 'center'}}>{t("general.gw")} {gwDetails.gw}</h1>
        {gwDetails.status === 'SCHEDULED' && <h4 style={{marginTop: '30px'}}>{t("predictions.scheduled")}</h4>}
        {(gwDetails.status === 'PENDING' || gwDetails.status === 'FINISHED') && <PredictionsResult gwDetails={gwDetails} />}
        {gwDetails.status === 'OPEN' && <PredictionsEdit gwDetails={gwDetails} />}
      </div>
    ) : (
      <Loading />
    ))}
    </>
  )
}

export default MainContent
