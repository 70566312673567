import { createSlice } from '@reduxjs/toolkit'
import { fetchSettings } from './settingsActions';

const initialState = {
  data: null,
  settingsError: false
}

const staffSlice = createSlice({
  name: 'settings',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchSettings.fulfilled, (state, action) => {
        state.data = action.payload
        state.settingsError = false
      })
      .addCase(fetchSettings.rejected, (state) => {
        state.settingsError = true
      });
  },
})

export default staffSlice.reducer;