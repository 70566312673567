import { createSlice } from "@reduxjs/toolkit"
import { createLeague, fetchLeagueDetails, joinLeague } from "./leaguesActions";
import { logoutUser } from "../auth/authActions";

const initialState = {
  leagueDetailsLoaded: false,
  leagueDetailsError: false,
  leagueDetails: null,
  createLeagueLoaded: true,
  createLeagueError: false,
  createdLeagueId: null,
  joinLeagueLoaded: true,
  joinLeagueError: null,
  joinedLeagueId: null,
}

const leaguesSlice = createSlice({
  name: 'leagues',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchLeagueDetails.pending, (state) => {
        state.leagueDetailsLoaded = false
        state.leagueDetailsError = false
        state.leagueDetails = null
      })
      .addCase(fetchLeagueDetails.fulfilled, (state, action) => {
        state.leagueDetailsLoaded = true
        state.leagueDetails = action.payload
      })
      .addCase(fetchLeagueDetails.rejected, (state) => {
        state.leagueDetailsLoaded = true
        state.leagueDetailsError = true
      })
      .addCase(createLeague.pending, (state) => {
        state.createLeagueLoaded = false
        state.createLeagueError = false
        state.createdLeagueId = null
      })
      .addCase(createLeague.fulfilled, (state, action) => {
        state.createLeagueLoaded = true
        state.createLeagueError = false
        state.createdLeagueId = action.payload
      })
      .addCase(createLeague.rejected, (state) => {
        state.createLeagueLoaded = true
        state.createLeagueError = true
      })
      .addCase(joinLeague.pending, (state) => {
        state.joinLeagueLoaded = false
        state.joinLeagueError = null
        state.joinedLeagueId = null
      })
      .addCase(joinLeague.fulfilled, (state, action) => {
        state.joinLeagueLoaded = true
        state.joinLeagueError = null
        state.joinedLeagueId = action.payload
      })
      .addCase(joinLeague.rejected, (state, action) => {
        state.joinLeagueLoaded = true
        state.joinLeagueError = action.payload
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.leagueDetails = {}
      });
  },
})

export default leaguesSlice.reducer;