import React, { useState } from 'react';
import moment from "moment";
import { useTranslation } from 'react-i18next';
import MatchModal from './MatchModal';

function GwsMatchManagement ({match, index, edit, onEdit, onRemove}) {
  const { t } = useTranslation();
  const [editMatchModal, setEditMatchModal] = useState(false);
  return (
    <>
      <div style={{marginTop: '20px'}}>
        <div style={{fontWeight: 'bold'}}>{match.home}-{match.away}</div>
        <div>{match.start ? moment(parseInt(match.start)).format('DD/MM/YYYY HH:mm') : t("staff.missingMatchDate")}</div>
        {match.excluded && (
          <>
            <div>{t("staff.homeExcluded")}: {match.excluded.home && match.excluded.home.join(',')}</div>
            <div>{t("staff.awayExcluded")}: {match.excluded.away && match.excluded.away.join(',')}</div>
          </>
        )}
        {edit && (
          <div>
            <button className='edit' onClick={() => setEditMatchModal(true)}>{t('general.edit')}</button>
            <button className='remove' style={{marginLeft: '10px'}} onClick={() => onRemove(index)}>{t('general.remove')}</button>
          </div>
        )}
      </div>
      {editMatchModal && <MatchModal toggle={() => setEditMatchModal(false)} onSubmit={(data) => onEdit(index, data)} match={match} />}
    </>
  )
}

export default GwsMatchManagement;
