import React from 'react'

function Footer () {
  return (
    <footer class="bg-blue-50 rounded-lg shadow m-4">
      <div class="w-full mx-auto max-w-screen-xl p-4 inline-flex sm:items-center">
        <span class="ml-8 self-center text-2xl font-semibold whitespace-nowrap">powered by</span>
        <a target='_blank' href='https://fplitalia.com' rel="noreferrer">
            <img src='/header.png' alt='logo' class="h-24 -ml-4"/>
          </a>
      </div>
    </footer>
  )
}

export default Footer
